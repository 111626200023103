<template>
  <g>
    <svg:style>
      .cls-1,
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke:#aeaeae;
        stroke-width:0.25px;
      }

      .cls-1,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-2,
      .cls-3 {
        stroke-miterlimit:10;
      }

      .cls-1 {
        fill:url(#BG-gradient);
      }

      .cls-2 {
        fill:none;
      }

      .cls-3 {
        fill:url(#handle-gradient);
      }

      .cls-10,
      .cls-11,
      .cls-12,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9 {
        stroke-linecap:round;
        stroke-linejoin:round;
      }

      .cls-4 {
        fill:url(#inox-gradient);
      }


      .cls-13 {
        fill:url(#glass-pattern);
      }

      .cls-14 {
        fill:url(#glass-pattern);
      }

      .cls-15 {
        fill:url(#glass-pattern);
      }

      .cls-16 {
        fill:url(#glass-pattern);
      }

      .cls-17{
        fill:url(#glass-pattern);
      }
    </svg:style>

    <linearGradient id="BG-gradient"
                    x1="71.07"
                    y1="0.12"
                    x2="71.07"
                    y2="298.13"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.25" stop-color="#f9f9f9"/>
      <stop offset="0.67" stop-color="#e8e8e8"/>
      <stop offset="0.79" stop-color="#e2e2e2"/>
    </linearGradient>

    <linearGradient id="handle-gradient"
                    x1="11.33"
                    y1="150.96"
                    x2="17.01"
                    y2="150.96"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>

    <linearGradient id="inox-gradient"
                    x1="70.9"
                    y1="250.36"
                    x2="70.9"
                    y2="52.21"
                    xlink:href="#handle-gradient"/>

    <linearGradient id="BG-gradient-2"
                    x1="52.19"
                    y1="57.48"
                    x2="52.19"
                    y2="89.77"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="BG-gradient-3"
                    x1="87.84"
                    y1="124.07"
                    x2="87.84"
                    y2="178.71"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="BG-gradient-4"
                    x1="86.83"
                    y1="94.7"
                    x2="86.83"
                    y2="104.63"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="BG-gradient-5"
                    x1="86.83"
                    y1="109.56"
                    x2="86.83"
                    y2="119.48"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="BG-gradient-6"
                    x1="89.82"
                    y1="212.87"
                    x2="89.82"
                    y2="245.43"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="BG-gradient-7"
                    x1="55.03"
                    y1="198.01"
                    x2="55.03"
                    y2="207.94"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="BG-gradient-8"
                    x1="55.03"
                    y1="183.15"
                    x2="55.03"
                    y2="193.08"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="BG-gradient-9"
                    x1="62.08"
                    y1="128.02"
                    x2="62.08"
                    y2="146.35"
                    xlink:href="#BG-gradient"/>

    <linearGradient id="glass-gradient"
                    x1="77.98"
                    y1="89.77"
                    x2="77.98"
                    y2="57.13"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>

    <linearGradient id="glass-gradient-2"
                    x1="56.77"
                    y1="174.68"
                    x2="56.77"
                    y2="156.21"
                    xlink:href="#glass-gradient"/>

    <linearGradient id="glass-gradient-3"
                    x1="61.7"
                    y1="118.1"
                    x2="61.7"
                    y2="99.63"
                    xlink:href="#glass-gradient"/>

    <linearGradient id="glass-gradient-4"
                    x1="63.85"
                    y1="245.43"
                    x2="63.85"
                    y2="212.8"
                    xlink:href="#glass-gradient"/>

    <linearGradient id="glass-gradient-5"
                    x1="80.1"
                    y1="202.87"
                    x2="80.1"
                    y2="184.4"
                    xlink:href="#glass-gradient"/>

    <g id="s25">
      <rect id="inox"
            v-if="showInox"
            class="cls-4"
            :x="doorLeftWidth1 + 42.54"
            :y="doorTopHeight1 + 52.21"
            width="56.73"
            height="198.16"/>

      <rect id="lamination"
            :fill="laminationId"
            class="cls-5"
            :x="doorLeftWidth1 + 47.6"
            :y="doorTopHeight1 + 57.48"
            width="9.16"
            height="32.29"/>

      <rect id="lamination-2"
            :fill="laminationId"
            data-name="lamination"
            class="cls-6"
            :x="doorLeftWidth1 + 81.49"
            :y="doorTopHeight1 + 124.07"
            width="12.71"
            height="54.64"/>

      <rect id="lamination-3"
            :fill="laminationId"
            data-name="lamination"
            class="cls-7"
            :x="doorLeftWidth1 + 79.4"
            :y="doorTopHeight1 + 94.7"
            width="14.86"
            height="9.93"/>

      <rect id="lamination-4"
            :fill="laminationId"
            data-name="lamination"
            class="cls-8"
            :x="doorLeftWidth1 + 79.4"
            :y="doorTopHeight1 + 109.56"
            width="14.86"
            height="9.93"/>

      <rect id="lamination-5"
            :fill="laminationId"
            data-name="lamination"
            class="cls-9"
            :x="doorLeftWidth1 + 85.38"
            :y="doorTopHeight1 + 212.87"
            width="8.89"
            height="32.56"/>

      <rect id="lamination-6"
            :fill="laminationId"
            data-name="lamination"
            class="cls-10"
            :x="doorLeftWidth1 + 47.6"
            :y="doorTopHeight1 + 198.01"
            width="14.86"
            height="9.93"/>

      <rect id="lamination-7"
            :fill="laminationId"
            data-name="lamination"
            class="cls-11"
            :x="doorLeftWidth1 + 47.6"
            :y="doorTopHeight1 + 183.15"
            width="14.86"
            height="9.93"/>

      <rect id="lamination-8"
            :fill="laminationId"
            data-name="lamination"
            class="cls-12"
            :x="doorLeftWidth1 + 47.6"
            :y="doorTopHeight1 + 128.02"
            width="28.95"
            height="18.33"/>

      <rect id="glass"
            class="cls-13"
            :x="doorLeftWidth1 + 61.7"
            :y="doorTopHeight1 + 57.13"
            width="32.56"
            height="32.63"/>

      <rect id="glass-2"
            data-name="glass"
            class="cls-14"
            :x="doorLeftWidth1 + 47.54"
            :y="doorTopHeight1 + 156.21"
            width="18.47"
            height="18.47"/>

      <rect id="glass-3"
            data-name="glass"
            class="cls-15"
            :x="doorLeftWidth1 + 52.47"
            :y="doorTopHeight1 + 99.63"
            width="18.47"
            height="18.47"/>

      <rect id="glass-4"
            data-name="glass"
            class="cls-16"
            :x="doorLeftWidth1 + 47.54"
            :y="doorTopHeight1 + 212.8"
            width="32.63"
            height="32.63"/>

      <rect id="glass-5"
            data-name="glass"
            class="cls-17"
            :x="doorLeftWidth1 + 70.86"
            :y="doorTopHeight1 + 184.4"
            width="18.47"
            height="18.47"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'laminationId',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
